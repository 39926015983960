import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderPlus } from '@fortawesome/free-solid-svg-icons';
import { database } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import { ROOT_FOLDER } from '../../hooks/useFolder';

export default function AddFolderButton({ currentFolder }) {
   const [open, setOpen] = useState(false);
   const [name, setName] = useState('');
   const { currentUser } = useAuth();
   const [isdbUserVerified, setDbUser] = useState(false);

   useEffect(() => {
      database.users
         .where('email', '==', currentUser.email)
         .get()
         .then(user => {
            if (!user.docs[0]) return;

            setDbUser(user.docs[0].data().isVerified);
         });
   }, [currentUser, isdbUserVerified]);

   function openModal() {
      setOpen(true);
   }

   function closeModal() {
      setOpen(false);
   }

   function handleSubmit(e) {
      e.preventDefault();

      if (currentFolder == null) return;
      const path = [...currentFolder.path];
      if (currentFolder !== ROOT_FOLDER) {
         path.push({ name: currentFolder.name, id: currentFolder.id });
      }

      database.folders.add({
         name: name,
         originalName: name,
         parentId: currentFolder.id,
         userId: currentUser.uid,
         path: path,
         friendIds: [],
         userEmail: currentUser.email,
         createdAt: database.getCurrentTimestamp(),
      });
      setName('');
      closeModal();
   }

   return (
      <>
         <Button
            onClick={openModal}
            variant="outline-success"
            size="sm"
            disabled={!isdbUserVerified}
            //disabled="true"
         >
            <FontAwesomeIcon icon={faFolderPlus} /> Create Folder
         </Button>
         <Modal show={open} onHide={closeModal}>
            <Form onSubmit={handleSubmit}>
               <Modal.Body>
                  <Form.Group>
                     <Form.Label>Folder Name</Form.Label>
                     <Form.Control
                        type="text"
                        required
                        value={name}
                        onChange={e => setName(e.target.value)}
                     />
                  </Form.Group>
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="success" type="submit">
                     Add Folder
                  </Button>
                  <Button variant="secondary" onClick={closeModal}>
                     Cancel
                  </Button>
                  
               </Modal.Footer>
            </Form>
         </Modal>
      </>
   );
}

import React, { useRef, useState } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import { Link, useHistory } from 'react-router-dom';
import CenteredContainer from './CenteredContainer';
import firebase from 'firebase';
import { database } from '../../firebase';
export default function Signup() {
   const emailRef = useRef();
   const passwordRef = useRef();
   const passwordConfirmRef = useRef();
   const { signup } = useAuth();
   const [error, setError] = useState('');
   const [loading, setLoading] = useState(false);
   const history = useHistory();

   async function handleSubmit(e) {
      e.preventDefault();

      if (passwordRef.current.value !== passwordConfirmRef.current.value) {
         return setError('Passwords do not match');
      }

      try {
         setError('');
         setLoading(true);
         await signup(emailRef.current.value, passwordRef.current.value);
         firebase.auth().onAuthStateChanged(user => {
            if (!user) return;
            database.users
               .where('email', '==', user.email)
               .get()
               .then(existingFiles => {
                  const existingFile = existingFiles.docs[0];
                  if (existingFile && user.loginMethod === 'Email') {
                     throw Error('Email is already in use');
                  } else {
                     database.users.add({
                        email: user.email,
                        id: user.uid,
                        createdAt: database.getCurrentTimestamp(),
                        loginMethod: 'Email',
                        isVerified: false,
                     });
                  }
               });
         });
         history.push('/');
      } catch (error) {
         setError('Failed to create an account');
         //console.log(error);
      }

      setLoading(false);
   }

   return (
      <CenteredContainer>
         <Card>
            <Card.Body>
               <h2 className="text-center mb-4">Sign Up</h2>
               {error && <Alert variant="danger">{error}</Alert>}
               <Form onSubmit={handleSubmit}>
                  <Form.Group id="email">
                     <Form.Label>Email</Form.Label>
                     <Form.Control type="email" ref={emailRef} required />
                  </Form.Group>
                  <Form.Group id="password">
                     <Form.Label>Password</Form.Label>
                     <Form.Control type="password" ref={passwordRef} required />
                  </Form.Group>
                  <Form.Group id="password-confirm">
                     <Form.Label>Password Confirmation</Form.Label>
                     <Form.Control
                        type="password"
                        ref={passwordConfirmRef}
                        required
                     />
                  </Form.Group>
                  <Button disabled={loading} className="w-100" type="submit">
                     Sign Up
                  </Button>
               </Form>
            </Card.Body>
         </Card>
         <div className="w-100 text-center mt-2">
            Already have an account? <Link to="/login">Log In</Link>
         </div>
      </CenteredContainer>
   );
}

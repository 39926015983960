import React from 'react';
import { Container, Table } from 'react-bootstrap';
import { useFolder } from '../../hooks/useFolder';
import AddFolderButton from './AddFolderButton';
import AddFileButton from './AddFileButton';
import AddFriendButton from './AddFriendButton';
import DeleteButton from './DeleteButton';
import Folder from './Folder';
import File from './File';
import Navbar from './Navbar';
import FolderBreadcrumbs from './FolderBreadcrumbs';
import { useParams, useLocation } from 'react-router-dom';
import useScript from '../../hooks/useScript';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import EditFileButton from './EditFileButton';
import { useAuth } from '../../contexts/AuthContext';
import { ROOT_FOLDER } from '../../hooks/useFolder';

export default function Dashboard() {
   const { currentUser } = useAuth();
   const { folderId } = useParams();
   const { state = {} } = useLocation();
   const { folder, childFolders, childFiles, friendFiles, friendFolders } = useFolder(
      folderId,
      state.folder
   );

   useScript('https://www.kryogenix.org/code/browser/sorttable/sorttable.js');
   function search() {
      // Declare variables
      var input,
         filter,
         table,
         tr,
         str,
         td,
         std,
         i,
         txtValue,
         stxtValue,
         shared_table;
      input = document.getElementById('search_term');
      filter = input.value.toUpperCase();
      table = document.getElementById('dashboard_table');
      tr = table.getElementsByTagName('tr');
      if (friendFiles.length > 0 || friendFolders.length > 0) {
         shared_table = document.getElementById('shared_table');
         str = shared_table.getElementsByTagName('tr');
      }

      // Loop through all table rows, and hide those who don't match the search query
      for (i = 0; i < tr.length; i++) {
         td = tr[i].getElementsByTagName('td')[0];
         if (td) {
            txtValue = td.textContent || td.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
               tr[i].style.display = '';
            } else {
               tr[i].style.display = 'none';
            }
         }
      }
      if (friendFiles.length > 0 || friendFolders.length > 0) {
         for (i = 0; i < str.length; i++) {
            std = str[i].getElementsByTagName('td')[0];
            if (std) {
               stxtValue = std.textContent || std.innerText;
               if (stxtValue.toUpperCase().indexOf(filter) > -1) {
                  str[i].style.display = '';
               } else {
                  str[i].style.display = 'none';
               }
            }
         }
      }
   }
   function getFileSize(size) {
      if (size > 1073741824 * 10) return '>10GB';
      else if (size > 1073741824) return `${Math.floor(size / 1073741824)}GB`;
      else if (size > 1048576) return `${Math.floor(size / 1048576)}MB`;
      else if (size > 1024) return `${Math.floor(size / 1024)}KB`;
      else return `${size}B`;
   }
   function handleCopy(url, id) {
      navigator.clipboard.writeText(url);
      let button = document.getElementById(`button_${id}`);
      button.innerText = 'Copied';
   }
   return (
      <>
         <Navbar currentPage={folder}/>
         <Container fluid>
            <script src="./sorttable.js"></script>
            <div className="d-flex align-items-center">
               <FolderBreadcrumbs currentFolder={folder} />
               <AddFileButton currentFolder={folder} />
               <AddFolderButton currentFolder={folder} />
               {(childFolders.length > 0 ||
                  childFiles.length > 0 ||
                  friendFiles.length > 0 ||
                  friendFolders.length > 0) && (
                  <input
                     type="text"
                     id="search_term"
                     onKeyUp={search}
                     placeholder="Search file name here"
                     className="m-2 p-1 w-25 border square rounded border-primary"
                  />
               )}
            </div>
            {(childFolders.length > 0 || childFiles.length > 0) && (
               <Table
                  striped
                  bordered
                  hover
                  size="sm"
                  id="dashboard_table"
                  className="sortable"
               >
                  <thead>
                     <tr>
                        <th style={{ cursor: 'pointer', textAlign: 'center' }}>
                           Name
                        </th>
                        <th style={{ cursor: 'pointer', textAlign: 'center' }}>
                           Last Modified Date
                        </th>
                        <th style={{ cursor: 'pointer', textAlign: 'center' }}>
                           Uploaded Date
                        </th>
                        <th style={{ cursor: 'pointer', textAlign: 'center' }}>
                           Size
                        </th>
                        <th style={{ cursor: 'pointer', textAlign: 'center' }}>
                           Type
                        </th>
                        {folder.userId === currentUser.uid &&
                        <th style={{ cursor: 'pointer', textAlign: 'center' }}>
                           Actions
                        </th>}
                     </tr>
                  </thead>
                  <tbody>
                     {childFolders.length > 0 &&
                        childFolders.map(childFolder => (
                           <tr key={childFolder.id} className="item">
                              <td>
                                 <Folder folder={childFolder} />
                              </td>
                              <td style={{ textAlign: 'center' }}>-</td>
                              <td style={{ textAlign: 'center' }}>
                                 {childFolder.createdAt &&
                                    childFolder.createdAt
                                       .toDate()
                                       .toLocaleDateString('en-US', {
                                          weekday: 'long',
                                          year: 'numeric',
                                          month: 'long',
                                          day: 'numeric',
                                       })}
                              </td>
                              <td style={{ textAlign: 'center' }}>-</td>
                              <td style={{ textAlign: 'center' }}>
                                 {childFolder.type || 'Folder'}
                              </td>

                              <td style={{ textAlign: 'center' }}>
                                 <EditFileButton isFolder={true} currentFile={childFolder} />

                                 {/*<AddFriendButton isFolder={true} currentFile={childFolder} />*/}
                                 <DeleteButton
                                    isFolder={true}
                                    currentFile={childFolder}
                                    currentFolder={folder}
                                 />
                              </td>
                              
                           </tr>
                        ))}
                     {childFiles.length > 0 &&
                        childFiles.map(childFile => (
                           <tr key={childFile.id} className="item">
                              <td>
                                 <File file={childFile} shared={currentUser.uid !== folder.userId} />
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                 {childFile.createdAt &&
                                    childFile.createdAt
                                       .toDate()
                                       .toLocaleDateString('en-US', {
                                          weekday: 'long',
                                          year: 'numeric',
                                          month: 'long',
                                          day: 'numeric',
                                       })}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                 {childFile.lastModifiedDate &&
                                    childFile.lastModifiedDate
                                       .toDate()
                                       .toLocaleDateString('en-US', {
                                          weekday: 'long',
                                          year: 'numeric',
                                          month: 'long',
                                          day: 'numeric',
                                       })}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                 {getFileSize(childFile.size)}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                 {childFile.type || (folder.userId === currentUser.uid ? "Unknown" : "Shared")}
                              </td>{(folder.userId === currentUser.uid  || folder === ROOT_FOLDER) && 
                              <td style={{ textAlign: 'center' }}>
                                 
                                    <><button
                                       id={`button_${childFile.id}`}
                                       className="btn btn-outline-info btn-sm mr-2"
                                       onClick={() => handleCopy(childFile.url, childFile.id)}
                                    >
                                       <FontAwesomeIcon icon={faCopy} />
                                    </button><EditFileButton currentFile={childFile} /><AddFriendButton currentFile={childFile} /><DeleteButton
                                          currentFile={childFile}
                                          currentFolder={folder} /></>
                                
                                 
                              </td> }
                           </tr>
                        ))}
                  </tbody>
               </Table>
            )}
            <br />
            {(friendFiles.length > 0 || friendFolders.length > 0)&& folder.name === 'Home' && (
               <Table
                  striped
                  bordered
                  hover
                  size="sm"
                  id="shared_table"
                  className="sortable"
               >
                  <thead>
                     <tr>
                        <th style={{ cursor: 'pointer', textAlign: 'center' }}>
                           Shared Item
                        </th>
                        <th style={{ cursor: 'pointer', textAlign: 'center' }}>
                           Last Modified Date
                        </th>
                        <th style={{ cursor: 'pointer', textAlign: 'center' }}>
                           Uploaded Date
                        </th>
                        <th style={{ cursor: 'pointer', textAlign: 'center' }}>
                           Size
                        </th>
                        <th style={{ cursor: 'pointer', textAlign: 'center' }}>
                           Shared By
                        </th>
                     </tr>
                  </thead>
                  <tbody>
                     {friendFiles.length > 0 &&
                        friendFiles.map(childFile => (
                           <tr key={childFile.id} className="item">
                              <td>
                                 <File file={childFile} shared={true} />
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                 {childFile.lastModifiedDate &&
                                    childFile.lastModifiedDate
                                       .toDate()
                                       .toLocaleDateString('en-US', {
                                          weekday: 'long',
                                          year: 'numeric',
                                          month: 'long',
                                          day: 'numeric',
                                       })}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                 {childFile.createdAt &&
                                    childFile.createdAt
                                       .toDate()
                                       .toLocaleDateString('en-US', {
                                          weekday: 'long',
                                          year: 'numeric',
                                          month: 'long',
                                          day: 'numeric',
                                       })}
                              </td>

                              <td style={{ textAlign: 'center' }}>
                                 {getFileSize(childFile.size)}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                 <b>{childFile.userEmail}</b>
                              </td>
                           </tr>
                        ))}
                     {friendFolders.length > 0 &&
                     friendFolders.map(friendFolder => (
                        <tr key={friendFolder.id} className="item">
                           <td>
                              <Folder folder={friendFolder} />
                           </td>
                           <td style={{ textAlign: 'center' }}>-</td>
                           <td style={{ textAlign: 'center' }}>
                              {friendFolder.createdAt &&
                                 friendFolder.createdAt
                                    .toDate()
                                    .toLocaleDateString('en-US', {
                                       weekday: 'long',
                                       year: 'numeric',
                                       month: 'long',
                                       day: 'numeric',
                                    })}
                           </td>
                           <td style={{ textAlign: 'center' }}>-</td>
                           <td style={{ textAlign: 'center' }}>
                              <b>{friendFolder.userEmail}</b>
                           </td>
                        </tr>
                     ))}
                  </tbody>
               </Table>
            )}
         </Container>
      </>
   );
}

import React, { useRef, useState } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import { Link, useHistory, Redirect } from 'react-router-dom';
import CenteredContainer from './CenteredContainer';
import 'firebase/auth';
import firebase from 'firebase';
import { database } from '../../firebase';
export default function Login() {
   const emailRef = useRef();
   const passwordRef = useRef();
   const { login, signInWithGoogle, currentUser } = useAuth();
   const [error, setError] = useState('');
   const [loading, setLoading] = useState(false);
   const history = useHistory();

   async function handleSubmit(e) {
      e.preventDefault();

      try {
         setError('');
         setLoading(true);
         await login(emailRef.current.value, passwordRef.current.value);
         history.push('/');
      } catch {
         setError('Failed to log in');
      }

      setLoading(false);
   }
   async function loginGoogle(e) {
      try {
         setError('');
         setLoading(true);
         await signInWithGoogle();
         firebase.auth().onAuthStateChanged(user => {
            console.log(user)
            if (!user) return;
            database.users
               .where('email', '==', user.email)
               .get()
               .then(existingFiles => {
                  const existingFile = existingFiles.docs[0];
                  if (existingFile) return;

                  database.users.add({
                     email: user.email,
                     id: user.uid,
                     createdAt: database.getCurrentTimestamp(),
                     loginMethod: 'Google',
                     isVerified: false,
                  });
               });
         });
         history.push('/');
      } catch (e) {
         setError('Failed to log in');
         console.log(e);
      }

      setLoading(false);
   }
   return (
      <CenteredContainer>
         {currentUser && <Redirect to="/" />}

         <Card>
            <Card.Body>
               <h2 className="text-center mb-4">Log In</h2>
               {error && <Alert variant="danger">{error}</Alert>}
               <Form onSubmit={handleSubmit}>
                  <Form.Group id="email">
                     <Form.Label>Email</Form.Label>
                     <Form.Control type="email" ref={emailRef} required />
                  </Form.Group>
                  <Form.Group id="password">
                     <Form.Label>Password</Form.Label>
                     <Form.Control type="password" ref={passwordRef} required />
                  </Form.Group>
                  <Button disabled={loading} className="w-100" type="submit">
                     Log In
                  </Button>
               </Form>
               <div className="w-100 text-center mt-3">
                  <Link to="/forgot-password">Forgot Password?</Link>
               </div>
            </Card.Body>
         </Card>
         <div className="w-100 text-center mt-2">
            Need an account? <Link to="/signup">Sign Up</Link>
         </div>
         <div className="w-100 text-center mt-2">
            <Button onClick={loginGoogle}>Sign In Using Google</Button>
         </div>
      </CenteredContainer>
   );
}

import { useReducer, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { database } from '../firebase';

const ACTIONS = {
   SELECT_FOLDER: 'select-folder',
   UPDATE_FOLDER: 'update-folder',
   SET_CHILD_FOLDERS: 'set-child-folders',
   SET_CHILD_FILES: 'set-child-files',
   SET_FRIEND_FILES: 'set-friend-files',
   SET_FRIEND_FOLDERS: 'set-friend-folders'
};

export const ROOT_FOLDER = { name: 'Home', id: 'home_folder', path: [] };

function reducer(state, { type, payload }) {
   switch (type) {
      case ACTIONS.SELECT_FOLDER:
         return {
            folderId: payload.folderId,
            folder: payload.folder,
            childFiles: [],
            childFolders: [],
            friendFiles: [],
            friendFolders: []
         };
      case ACTIONS.UPDATE_FOLDER:
         return {
            ...state,
            folder: payload.folder,
         };
      case ACTIONS.SET_CHILD_FOLDERS:
         return {
            ...state,
            childFolders: payload.childFolders,
         };
      case ACTIONS.SET_CHILD_FILES:
         return {
            ...state,
            childFiles: payload.childFiles,
         };
      case ACTIONS.SET_FRIEND_FILES:
         return {
            ...state,
            friendFiles: payload.friendFiles,
         };
      case ACTIONS.SET_FRIEND_FOLDERS:
         return {
            ...state,
            friendFolders: payload.friendFolders,
         };
      default:
         return state;
   }
}

export function useFolder(folderId = 'home_folder', folder = null) {
   const [state, dispatch] = useReducer(reducer, {
      folderId,
      folder,
      childFolders: [],
      childFiles: [],
      friendFiles: [],
      friendFolders: []
   });
   const { currentUser } = useAuth();

   useEffect(() => {
      dispatch({ type: ACTIONS.SELECT_FOLDER, payload: { folderId, folder } });
   }, [folderId, folder]);

   useEffect(() => {
      if (folderId == null || folderId === 'home_folder') {
         return dispatch({
            type: ACTIONS.UPDATE_FOLDER,
            payload: { folder: ROOT_FOLDER },
         });
      }

      database.folders
         .doc(folderId)
         .get()
         .then(doc => {
            dispatch({
               type: ACTIONS.UPDATE_FOLDER,
               payload: { folder: database.formatDoc(doc) },
            });
         })
         .catch(() => {
            dispatch({
               type: ACTIONS.UPDATE_FOLDER,
               payload: { folder: ROOT_FOLDER },
            });
         });
   }, [folderId]);

   useEffect(() => {
      return database.folders
         .where('parentId', '==', folderId)
         .where('userId', '==', currentUser.uid)
         //.orderBy('createdAt')
         .onSnapshot(snapshot => {
            dispatch({
               type: ACTIONS.SET_CHILD_FOLDERS,
               payload: { childFolders: snapshot.docs.map(database.formatDoc) },
            });
         });
   }, [folderId, currentUser]);

   useEffect(() => {
      return (
         database.files
            .where('folderId', '==', folderId)
            .where('userId', '==', currentUser.uid)
            //.orderBy("createdAt")
            .onSnapshot(snapshot => {
               dispatch({
                  type: ACTIONS.SET_CHILD_FILES,
                  payload: { childFiles: snapshot.docs.map(database.formatDoc) },
               });
            })
      );
   }, [folderId, currentUser]);
   useEffect(() => {
      return (
         database.files
            .where('friendIds', 'array-contains', currentUser.uid)
            
            // .orderBy("createdAt")
            .onSnapshot(snapshot => {
               dispatch({
                  type: ACTIONS.SET_FRIEND_FILES,
                  payload: { friendFiles: snapshot.docs.map(database.formatDoc) },
               });
            })
      );
   }, [folderId, currentUser]);


   useEffect(() => {
      return (
         database.folders
            .where('friendIds', 'array-contains', currentUser.uid)
            // .orderBy("createdAt")
            .onSnapshot(snapshot => {
               dispatch({
                  type: ACTIONS.SET_FRIEND_FOLDERS,
                  payload: { friendFolders: snapshot.docs.map(database.formatDoc) },
               });
            })
      );
   }, [folderId, currentUser]);
   return state;
}

import React from 'react';
// import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ROOT_FOLDER } from '../../hooks/useFolder';

export default function NavbarComponent({currentPage}) {
   return (
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
         <Container fluid>
            
            <Navbar.Brand as={currentPage !== ROOT_FOLDER ? Link: ""}  to="/">
               Simple Drive
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
               <Nav className="ml-auto">
                  <Nav.Link as={Link} to="/user">
                     Profile
                  </Nav.Link>
                  {/* <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                        <NavDropdown.Item href="/user">
                           Profile
                        </NavDropdown.Item>
                     </NavDropdown> */}
               </Nav>
            </Navbar.Collapse>
         </Container>
      </Navbar>
   );
}

import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { database } from '../../firebase';
import firebase from 'firebase';
export default function AddFriendButton({ currentFile, isFolder }) {
   const [open, setOpen] = useState(false);
   const [email, setEmail] = useState('');
   const [share, setShared] = useState('Share');
   const [shareDisable, setShareDisable] = useState(false);

   function openModal() {
      setOpen(true);
   }

   function closeModal() {
      setOpen(false);
   }

   async function handleSubmit(e) {
      e.preventDefault();
      setShared('Shared!');
      setShareDisable(true);
      database.users
         .where('email', '==', email)
         .get()
         .then(user => {
            const existingUser = user.docs[0];
            if (!existingUser) return;
            var o = {};
            var idToPush = existingUser.data().id;
            if (currentFile.friendIds.indexOf(idToPush) === -1)
               currentFile.friendIds.push(idToPush);
            o.friendIds = currentFile.friendIds;
            if(isFolder){
               database.folders
               .where(
                  firebase.firestore.FieldPath.documentId(),
                  '==',
                  currentFile.id
               )
               .get()
               .then(querySnapshot => {
                  querySnapshot.forEach(doc => {
                     doc.ref.update(o);
                  });
               });
            } else{
               database.files
               .where(
                  firebase.firestore.FieldPath.documentId(),
                  '==',
                  currentFile.id
               )
               .get()
               .then(querySnapshot => {
                  querySnapshot.forEach(doc => {
                     doc.ref.update(o);
                  });
               });
            }
            
         });
   }

   return (
      <>
         <Button onClick={openModal} variant="outline-primary" size="sm">
            <FontAwesomeIcon icon={faUserFriends} />{' '}
         </Button>
         <Modal show={open} onHide={closeModal}>
            <Form onSubmit={handleSubmit}>
               <Modal.Body>
                  <Form.Group>
                     <Form.Label>Friend's Email</Form.Label>
                     <Form.Control
                        type="text"
                        required
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                     />
                  </Form.Group>
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="primary" type="submit" disabled={shareDisable}>
                     {share}
                  </Button>
                  <Button variant="secondary" onClick={closeModal}>
                     Cancel
                  </Button>
                  
               </Modal.Footer>
            </Form>
         </Modal>
      </>
   );
}

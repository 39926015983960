import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '../../contexts/AuthContext';
import { storage, database } from '../../firebase';
import { ROOT_FOLDER } from '../../hooks/useFolder';
import { v4 as uuidV4 } from 'uuid';
import { ProgressBar, Toast } from 'react-bootstrap';

export default function AddFileButton({ currentFolder }) {
   const [uploadingFiles, setUploadingFiles] = useState([]);
   const { currentUser } = useAuth();
   const [isdbUserVerified, setDbUser] = useState(false);
   useEffect(() => {
      database.users
         .where('email', '==', currentUser.email)
         .get()
         .then(user => {
            if (!user.docs[0]) return;
            setDbUser(user.docs[0].data().isVerified);
         });
   }, [currentUser, isdbUserVerified]);
   function handleUpload(e) {
      const files = e.target.files;
      for (let file of files) {
         if (currentFolder == null || file == null) return;
         const id = uuidV4();
         setUploadingFiles(prevUploadingFiles => [
            ...prevUploadingFiles,
            { id: id, name: file.name, progress: 0, error: false },
         ]);
         const filePath =
            currentFolder === ROOT_FOLDER
               ? `${currentFolder.path.join('/')}/${file.name}`
               : `${currentFolder.path.join('/')}/${currentFolder.name}/${
                    file.name
                 }`;

         const uploadTask = storage
            .ref(`/files/${currentUser.uid}/${filePath}`)
            .put(file);

         uploadTask.on(
            'state_changed',
            snapshot => {
               const progress = snapshot.bytesTransferred / snapshot.totalBytes;
               setUploadingFiles(prevUploadingFiles => {
                  return prevUploadingFiles.map(uploadFile => {
                     if (uploadFile.id === id) {
                        return { ...uploadFile, progress: progress };
                     }

                     return uploadFile;
                  });
               });
            },
            () => {
               setUploadingFiles(prevUploadingFiles => {
                  return prevUploadingFiles.map(uploadFile => {
                     if (uploadFile.id === id) {
                        return { ...uploadFile, error: true };
                     }
                     return uploadFile;
                  });
               });
            },
            () => {
               setUploadingFiles(prevUploadingFiles => {
                  return prevUploadingFiles.filter(uploadFile => {
                     return uploadFile.id !== id;
                  });
               });

               uploadTask.snapshot.ref.getDownloadURL().then(url => {
                  database.files
                     .where('name', '==', file.name)
                     .where('userId', '==', currentUser.uid)
                     .where('folderId', '==', currentFolder.id)
                     .get()
                     .then(existingFiles => {
                        const existingFile = existingFiles.docs[0];
                        if (existingFile) {
                           existingFile.ref.update({ url: url });
                        } else {
                           try{
                              database.files.add({
                                 url: url,
                                 name: file.name,
                                 originalName: file.name,
                                 createdAt: database.getCurrentTimestamp(),
                                 folderId: currentFolder.id,
                                 userId: currentUser.uid,
                                 userEmail: currentUser.email,
                                 size: file.size,
                                 type: file.type,
                                 lastModifiedDate: file.lastModifiedDate,
                                 friendIds: [],
                                 folder: database.folders.doc(currentFolder.id)
                              });
                           } catch (e){
                              console.log(e)
                           }
                           
                        }
                     });
               });
            }
         );
      }
   }

   return (
      <>
         <label
            className={`btn-sm m-0 mr-2 btn btn-outline-success ${
               !isdbUserVerified ? 'disabled' : ''
            }`}
         >
            <FontAwesomeIcon icon={faFileUpload} />
            <input
               type="file"
               onChange={handleUpload}
               disabled={!isdbUserVerified}
               style={{ opacity: 0, position: 'absolute', left: '-9999px' }}
               multiple
            />{' '}
            Upload Files
         </label>
         {uploadingFiles.length > 0 &&
            ReactDOM.createPortal(
               <div
                  style={{
                     position: 'absolute',
                     bottom: '1rem',
                     right: '1rem',
                  }}
               >
                  {uploadingFiles.map(file => (
                     <Toast
                        key={file.id}
                        onClose={() => {
                           setUploadingFiles(prevUploadingFiles => {
                              return prevUploadingFiles.filter(uploadFile => {
                                 return uploadFile.id !== file.id;
                              });
                           });
                        }}
                     >
                        <Toast.Header
                           closeButton={file.error}
                           className="text-truncate w-100 d-block"
                        >
                           {file.name}
                        </Toast.Header>
                        <Toast.Body>
                           <ProgressBar
                              animated={!file.error}
                              variant={file.error ? 'danger' : 'primary'}
                              now={file.error ? 100 : file.progress * 100}
                              label={
                                 file.error
                                    ? 'Error'
                                    : `${Math.round(file.progress * 100)}%`
                              }
                           />
                        </Toast.Body>
                     </Toast>
                  ))}
               </div>,
               document.body
            )}
      </>
   );
}

import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { storage, database } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import firebase from 'firebase';
import { ROOT_FOLDER } from '../../hooks/useFolder';

export default function DeleteButton({ currentFile, currentFolder, isFolder }) {
   const [open, setOpen] = useState(false);
   const { currentUser } = useAuth();

   function openModal() {
      setOpen(true);
   }

   function closeModal() {
      setOpen(false);
   }
   function deleteFolder(path) {
      const ref = firebase.storage().ref(`/files/${currentUser.uid}/${path}`);
      ref.listAll()
        .then(dir => {
          dir.items.forEach(fileRef => {
             deleteFile(ref.fullPath, fileRef.name)
            });
          dir.prefixes.forEach(folderRef => {
             deleteFolder(folderRef.fullPath)
          })
        })
        .catch(error => console.log(error));
    }
    
    function deleteFile(pathToFile, fileName) {
      const ref = firebase.storage().ref(pathToFile);
      const childRef = ref.child(fileName);
      childRef.delete()
    }
   async function handleSubmit(e) {
      e.preventDefault();
      closeModal();

      if(isFolder){
         const filePath =
         currentFolder === ROOT_FOLDER
            ? `${currentFolder.path.join('/')}/${currentFile.originalName}`
            : `${currentFolder.path.join('/')}/${currentFolder.name}/${
                 currentFile.originalName
              }`;
         deleteFolder(filePath);
         database.files
            .where('folderId', '==', currentFile.id)
            .get()
            .then(querySnapshot => {
               querySnapshot.forEach(doc => {
                  doc.ref.delete();
               })
            });
         database.folders
            .where(firebase.firestore.FieldPath.documentId(), '==', currentFile.id)
            .get()
            .then(querySnapshot => {
               querySnapshot.forEach(doc => {
                  doc.ref.delete();
               });
            });
      } else{
         const filePath =
         currentFolder === ROOT_FOLDER
            ? `${currentFolder.path.join('/')}/${currentFile.originalName}`
            : `${currentFolder.path.join('/')}/${currentFolder.name}/${
                 currentFile.originalName
              }`;
         storage.ref(`/files/${currentUser.uid}/${filePath}`).delete();
         database.files
            .where(firebase.firestore.FieldPath.documentId(), '==', currentFile.id)
            .get()
            .then(querySnapshot => {
               querySnapshot.forEach(doc => {
                  doc.ref.delete();
               });
            });
      }

   }

   return (
      <>
         <Button
            onClick={openModal}
            variant="outline-danger"
            className="ml-2"
            size="sm"
         >
            <FontAwesomeIcon icon={faTrash} />{' '}
         </Button>
         <Modal show={open} onHide={closeModal}>
            <Form onSubmit={handleSubmit}>
               <Modal.Body>
                  <Form.Group>
                     <Form.Label>
                        {isFolder 
                           ? "Are you sure you want to delete this folder and all of its contents?" 
                           : "Are you sure you want to delete this file?"}
                     </Form.Label>
                  </Form.Group>
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="danger" type="submit">
                     {'Delete'}
                  </Button>
                  <Button variant="secondary" onClick={closeModal}>
                     Cancel
                  </Button>
               </Modal.Footer>
            </Form>
         </Modal>
      </>
   );
}

import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default function File({ file, shared }) {
   return (
      <div className="w-100">
         <FontAwesomeIcon icon={faFile} className="mr-2" />
         <a href={file.url} target="_blank" rel="noopener noreferrer">
            {file.name}  {shared && file.originalName !== file.name && `(${file.originalName})`}
         </a>
      </div>
   );
}

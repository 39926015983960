import React, { useContext, useState, useEffect } from 'react';
import { auth } from '../firebase';
import firebase from 'firebase/app';

const AuthContext = React.createContext();

export function useAuth() {
   return useContext(AuthContext);
}

export function AuthProvider({ children }) {
   const [currentUser, setCurrentUser] = useState();
   const [loading, setLoading] = useState(true);

   function signup(email, password) {
      return auth.createUserWithEmailAndPassword(email, password);
   }

   function login(email, password) {
      return auth.signInWithEmailAndPassword(email, password);
   }

   function logout() {
      return auth.signOut();
   }

   function resetPassword(email) {
      return auth.sendPasswordResetEmail(email);
   }

   function updateEmail(email) {
      return currentUser.updateEmail(email);
   }

   function updatePassword(password) {
      return currentUser.updatePassword(password);
   }
   function signInWithGoogle() {
      var provider = new firebase.auth.GoogleAuthProvider();
      firebase
         .auth()
         .signInWithPopup(provider)
         .then(function (result) {
            // This gives you a Google Access Token. You can use it to access the Google API.
            // firebase.auth.GoogleAuthProvider.credentialFromResult(result);
            // const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            setCurrentUser(user);
            // ...
         })
         .catch(error => {
            // Handle Errors here.
            //const errorCode = error.code;
            //const errorMessage = error.message;
            // The email of the user's account used.
            //const email = error.email;
            console.log(error);
            // ...
         });
   }
   useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged(user => {
         setCurrentUser(user);
         setLoading(false);
      });

      return unsubscribe;
   }, []);

   const value = {
      currentUser,
      login,
      signup,
      logout,
      resetPassword,
      updateEmail,
      updatePassword,
      signInWithGoogle,
   };

   return (
      <AuthContext.Provider value={value}>
         {!loading && children}
      </AuthContext.Provider>
   );
}

import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilRuler } from '@fortawesome/free-solid-svg-icons';
import { database } from '../../firebase';
import firebase from 'firebase';
export default function EditFileButton({ currentFile, isFolder }) {
   const [open, setOpen] = useState(false);
   const [fileRename, setFileRename] = useState('');

   function openModal() {
      setOpen(true);
   }

   function closeModal() {
      setOpen(false);
   }

   async function handleSubmit(e) {
      e.preventDefault();
      closeModal();
      if(isFolder){
         database.folders
         .where(firebase.firestore.FieldPath.documentId(), '==', currentFile.id)
         .get()
         .then(querySnapshot => {
            querySnapshot.forEach(doc => {
               doc.ref.update({ name: fileRename });
            });
         });   
      } else{
         database.files
            .where(firebase.firestore.FieldPath.documentId(), '==', currentFile.id)
            .get()
            .then(querySnapshot => {
               querySnapshot.forEach(doc => {
                  doc.ref.update({ name: fileRename });
               });
            });   
      }
      
   }

   return (
      <>
         <Button
            onClick={openModal}
            variant="outline-info"
            className="mr-2"
            size="sm"
         >
            <FontAwesomeIcon icon={faPencilRuler} />{' '}
         </Button>
         <Modal show={open} onHide={closeModal}>
            <Form onSubmit={handleSubmit}>
               <Modal.Body>
                  <Form.Group>
                     <Form.Label>{isFolder ?
                     "Enter the folder's new name:":
                     "Enter the file's new name:"}</Form.Label>
                     <Form.Control
                        type="text"
                        required
                        value={fileRename}
                        onChange={e => setFileRename(e.target.value)}
                     />
                  </Form.Group>
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="info" type="submit">
                     {'Rename'}
                  </Button>
                  <Button variant="secondary" onClick={closeModal}>
                     Cancel
                  </Button>
               </Modal.Footer>
            </Form>
         </Modal>
      </>
   );
}
